
import Glider from 'glider-js/glider.js'
var logosGlinder;
var logos = function () {
    
    var getLogos = function() {

        logosGlinder = new Glider(document.querySelector('.gliderlogos'), {
            // Mobile-first defaults
            slidesToShow: 1,
            slidesToScroll: 1,
            scrollLock: true,
            draggable: true,
            dots: '.dots',
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            },
            responsive: [
                {
                    // screens greater than >= 775px
                    breakpoint: 775,
                    settings: {
                    // Set to `auto` and provide item width to adjust to viewport
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    itemWidth: 150,
                    duration: 0.25
                    }
                },{
                    // screens greater than >= 1024px
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        itemWidth: 345,
                        duration: 0.25
                    }
                }
            ]
        });

        
        

    }
    return {
        init: function() {
            getLogos();
        }
    };
}();
    
$(function () {
    logos.init();
});
